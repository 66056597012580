.slick-track {
    display: flex !important;
}

.slick-slide {
    height: inherit !important;
}



/* Ensure images fit within their container */
.slick-slide img {
    width: 100%;
    height: 400px; /* Maintains aspect ratio */
    object-fit: cover; /* Ensures the image covers the container */
}

/* Optionally, set a fixed height for the slider container */

.slider-container {
    width: 100%;
    height: 300px; /* Set to desired height */
    overflow: hidden;
}

.slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}



