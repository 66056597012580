/* Loader.css */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F8F4F1; /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensure it is on top of all other content */
}

.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }
  
  .loader svg {
    width: 100px; /* Adjust size as needed */
    height: 100px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  